import { defaultStorageHelper } from "@spry/campaign-client";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

export default function Charities() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const data = defaultStorageHelper.get("dob");
    !data && navigate("/");
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main">
      <div className="main__decorations">
        <div className="main__decoration main__decoration--flower-dark-big">
          <img src="/assets/images/temp/flower-dark.svg" alt="" width="514" height="560" />
        </div>
        {/* /.main__decoration main__decoration-/-flower-dark-big */}

        <div className="main__decoration main__decoration--flower-dark-medium">
          <img src="/assets/images/temp/flower-dark.svg" alt="" width="441" height="480" />
        </div>
        {/* /.main__decoration main__decoration-/-flower-dark-medium */}

        <div className="main__decoration main__decoration--flower-dark-small">
          <img src="/assets/images/temp/flower-dark.svg" alt="" width="180" height="197" />
        </div>
        {/* /.main__decoration main__decoration-/-flower-dark-small */}

        <div className="main__decoration main__decoration--flower-light-big">
          <img src="/assets/images/temp/flower-light.svg" alt="" width="409" height="447" />
        </div>
        {/* /.main__decoration main__decoration-/-flower-light-big */}

        <div className="main__decoration main__decoration--flower-light-small">
          <img src="/assets/images/temp/flower-light.svg" alt="" width="234" height="256" />
        </div>
        {/* /.main__decoration main__decoration-/-flower-light-small */}

        <div className="main__decoration main__decoration--flower-light-smallest">
          <img src="/assets/images/temp/flower-light.svg" alt="" width="216" height="237" />
        </div>
        {/* /.main__decoration main__decoration-/-flower-light-smallest */}

        <div className="main__decoration main__decoration--leaf-gray-top">
          <img src="/assets/images/temp/leaf-grey@2x.png" alt="" width="123" height="111" />
        </div>
        {/* /.main__decoration main__decoration-/-leaf-gray-top */}

        <div className="main__decoration main__decoration--two-leaves decor-charities">
          <img src="/assets/images/temp/two-leaves@2x.png" alt="" width="160" height="174" />
        </div>
        <div className="main__decoration main__decoration--leaf-gray-bottom">
          <img src="/assets/images/temp/leaf-grey@2x.png" alt="" width="123" height="111" />
        </div>

        <div className="main__decoration main__decoration--leaf-gold">
          <img src="/assets/images/temp/leaf-gold@2x.png" alt="" width="78" height="134" />
        </div>
        {/* /.main__decoration main__decoration-/-leaf-gold */}
      </div>
      {/* /.main__decorations */}

      <div className="hero">
        <div className="shell shell--small">
          <div className="hero__inner">
            <Link to="/splash" className="hero__logo">
              <img
                src={
                  i18n.language === "en"
                    ? "/assets/images/temp/logo.svg"
                    : "/assets/images/svg/logo-fr.svg"
                }
                alt="Logo"
              />
            </Link>

            <div className="hero__content textLeft">
              <h1>{t("charity.1")}</h1>

              <p> {t("charity.2")} </p>
            </div>
            {/* /.hero__content */}
          </div>
          {/* /.hero__inner */}
        </div>
        {/* /.shell */}
      </div>
      {/* /.hero */}

      <section className="section ">
        <div className="shell shell--small">
          <div className="section__inner">
            <div className="cards__item flexCenter">
              <div className="card width70">
                <img src="/assets/images/temp/charities-d.jpg" alt="" />
              </div>
              {/* /.card */}
            </div>
            {/* /.cards__item */}
            <p className="small-text">{t("charity.3")}</p>
          </div>
          {/* /.section__inner */}
        </div>
        {/* /.shell */}
      </section>
      {/* /.section */}
    </div>
  );
}
