import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import useVex from "../../../hooks/useVex";
import { defaultStorageHelper } from "@spry/campaign-client";
import dayjs from "dayjs";

type KeywordForm = {
    day: string;
    month: string;
    year: string;
};

export default function AgeGate() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const form = useForm<KeywordForm>();
    const errors = form.formState.errors;
    const [error, setError] = useState("");
    const vex = useVex();

    useEffect(() => {
        defaultStorageHelper.remove("data");
    }, []);

    function tooYoung() {
        vex.dialog.alert({
            unsafeMessage: t("age.error"),
        });
    }

    async function submit(values: KeywordForm) {
        let minAge = 14;
        const day = parseInt(values.day);
        const month = parseInt(values.month);
        const year = parseInt(values.year);
        if (isNaN(day) || isNaN(month) || isNaN(year)) {
            return;
        }
        if (day < 1 || day > 31 || month < 1 || month > 12 || year < 1900 || year > 2100) {
            return;
        }
        const birthdate = new Date(year, month - 1, day);
        if (birthdate.getMonth() !== month - 1) {
            return;
        } /** Feb 31, etc */
        const now = new Date();
        const years = now.getFullYear() - birthdate.getFullYear();
        if (years < minAge) {
            return tooYoung();
        }
        if (years === minAge) {
            if (now.getMonth() < birthdate.getMonth()) {
                return tooYoung();
            }
            if (now.getMonth() === birthdate.getMonth()) {
                if (now.getDate() < birthdate.getDate()) {
                    return tooYoung();
                }
            }
        }

        defaultStorageHelper.set("dob", dayjs(year + "-" + month + "-" + day).format("YYYY-MM-DD"));
        setError("");
        navigate("/splash");
    }

    useEffect(() => {
        const dob = defaultStorageHelper.get("dob");
        if (dob) {
            navigate("/splash");
        }
    }, []);

    return (
        <div className="main">
            <div className="main__decorations">
                <div className="main__decoration main__decoration--flower-dark-big">
                    <img src="/assets/images/temp/flower-dark.svg" alt="" width="514" height="560" />
                </div>
                {/* /.main__decoration main__decoration-/-flower-dark-big */}

                <div className="main__decoration main__decoration--flower-dark-medium">
                    <img src="/assets/images/temp/flower-dark.svg" alt="" width="441" height="480" />
                </div>
                {/* /.main__decoration main__decoration-/-flower-dark-medium */}

                <div className="main__decoration main__decoration--flower-dark-small">
                    <img src="/assets/images/temp/flower-dark.svg" alt="" width="180" height="197" />
                </div>
                {/* /.main__decoration main__decoration-/-flower-dark-small */}

                <div className="main__decoration main__decoration--flower-light-big">
                    <img src="/assets/images/temp/flower-light.svg" alt="" width="409" height="447" />
                </div>
                {/* /.main__decoration main__decoration-/-flower-light-big */}

                <div className="main__decoration main__decoration--flower-light-small">
                    <img src="/assets/images/temp/flower-light.svg" alt="" width="234" height="256" />
                </div>
                {/* /.main__decoration main__decoration-/-flower-light-small */}

                <div className="main__decoration main__decoration--flower-light-smallest">
                    <img src="/assets/images/temp/flower-light.svg" alt="" width="216" height="237" />
                </div>
                {/* /.main__decoration main__decoration-/-flower-light-smallest */}

                <div className="main__decoration main__decoration--leaf-gray-top">
                    <img src="/assets/images/temp/leaf-grey@2x.png" alt="" width="123" height="111" />
                </div>
                {/* /.main__decoration main__decoration-/-leaf-gray-top */}

                <div className="main__decoration main__decoration--two-leaves decor-charities">
                    <img src="/assets/images/temp/two-leaves@2x.png" alt="" width="160" height="174" />
                </div>
                <div className="main__decoration main__decoration--leaf-gray-bottom">
                    <img src="/assets/images/temp/leaf-grey@2x.png" alt="" width="123" height="111" />
                </div>

                <div className="main__decoration main__decoration--leaf-gold">
                    <img src="/assets/images/temp/leaf-gold@2x.png" alt="" width="78" height="134" />
                </div>
                {/* /.main__decoration main__decoration-/-leaf-gold */}
            </div>
            {/* /.main__decorations */}

            <div className="hero padding-bottom">
                <div className="shell shell--small">
                    <div className="hero__inner">
                        <div className="hero__logo">
                            <img src={i18n.language === "en" ? "/assets/images/temp/logo.svg" : "/assets/images/svg/logo-fr.svg"} alt="Logo" />
                        </div>

                        <div className="hero__content textLeft">
                            <h1 className="age-title">{t("age.title")}</h1>

                            <form onSubmit={form.handleSubmit(submit)} className="form">
                                <div className="form__body">
                                    <div className="form__row">
                                        <div className="form__cols">
                                            <div className="form__col form__col--1of3">
                                                <div className="form__controls">
                                                    <label htmlFor="day" className="form__label">
                                                        {t("age.4")}
                                                    </label>
                                                    <input
                                                        {...form.register("day", {
                                                            required: true,
                                                            pattern: /^(0?[1-9]|[12][0-9]|3[0-1])$/,
                                                        })}
                                                        maxLength={2}
                                                        className={`field field--center ${errors.day ? "error" : ""}`}
                                                        placeholder={t("age.1")}
                                                        id="day"
                                                    />
                                                </div>
                                                {/* /.form__controls */}
                                            </div>
                                            {/* /.form__col form__col-/-1of3 */}

                                            <div className="form__col form__col--1of3">
                                                <div className="form__controls">
                                                    <label htmlFor="month" className="form__label">
                                                        {t("age.5")}
                                                    </label>
                                                    <input
                                                        {...form.register("month", {
                                                            required: true,
                                                            pattern: /^(0?[1-9]|1[0-2])$/,
                                                        })}
                                                        maxLength={2}
                                                        className={`field field--center ${errors.month ? "error" : ""}`}
                                                        placeholder={t("age.2")}
                                                        id="month"
                                                    />
                                                </div>
                                                {/* /.form__controls */}
                                            </div>
                                            {/* /.form__col form__col-/-1of3 */}

                                            <div className="form__col form__col--1of3">
                                                <div className="form__controls">
                                                    <label htmlFor="year" className="form__label">
                                                        {t("age.6")}
                                                    </label>
                                                    <input
                                                        {...form.register("year", {
                                                            required: true,
                                                            pattern: /^(19[0-9][0-9]|20[0-2][0-9])$/,
                                                        })}
                                                        maxLength={4}
                                                        className={`field field--center ${errors.year ? "error" : ""}`}
                                                        placeholder={t("age.3")}
                                                        id="year"
                                                    />
                                                </div>
                                                {/* /.form__controls */}
                                            </div>
                                            {/* /.form__col form__col-/-1of3 */}
                                        </div>
                                        {/* /.form__cols */}
                                    </div>
                                    {/* /.form__row */}
                                </div>
                                {/* /.form__body */}

                                <div className="form__actions">
                                    {error && (
                                        <span className="error">
                                            {error}
                                            <br />
                                            <br />
                                        </span>
                                    )}
                                    <button type="submit" className="btn btn--age">
                                        {t("age.7")}
                                    </button>
                                </div>
                                {/* /.form__actions */}
                            </form>
                        </div>
                        {/* /.hero__content */}
                    </div>
                    {/* /.hero__inner */}
                </div>
                {/* /.shell */}
            </div>
            {/* /.hero */}
        </div>
    );
}
