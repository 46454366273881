import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { campaignClient, getSessionClient } from "../../../api";
import { useEffect } from "react";
import { defaultStorageHelper } from "@spry/campaign-client";

export type EventClick = "learn" | "game" | "download" | "guideLink";

export async function eventClick(event: EventClick) {
  try {
    await campaignClient.call("event", {
      sessionKey: getSessionClient().__sessionKey,
      event,
    });
  } catch (e) {
    console.log(e);
  }
}
export const GameLinks = {
  en: "https://hideandfind.sharecadbury.ca/en",
  fr: "https://hideandfind.sharecadbury.ca/fr",
};

export default function Splash() {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === "en";
  const navigate = useNavigate();
  useEffect(() => {
    const data = defaultStorageHelper.get("dob");
    !data && navigate("/");
  }, []);

  return (
    <div className="main">
      <div className="main__decorations">
        <div className="main__decoration main__decoration--flower-dark-big">
          <img src="/assets/images/temp/flower-dark.svg" alt="" width="514" height="560" />
        </div>
        {/* /.main__decoration main__decoration-/-flower-dark-big */}

        <div className="main__decoration main__decoration--flower-dark-medium">
          <img src="/assets/images/temp/flower-dark.svg" alt="" width="441" height="480" />
        </div>
        {/* /.main__decoration main__decoration-/-flower-dark-medium */}

        <div className="main__decoration main__decoration--flower-dark-small">
          <img src="/assets/images/temp/flower-dark.svg" alt="" width="180" height="197" />
        </div>
        {/* /.main__decoration main__decoration-/-flower-dark-small */}

        <div className="main__decoration main__decoration--flower-light-big">
          <img src="/assets/images/temp/flower-light.svg" alt="" width="409" height="447" />
        </div>
        {/* /.main__decoration main__decoration-/-flower-light-big */}

        <div className="main__decoration main__decoration--flower-light-small">
          <img src="/assets/images/temp/flower-light.svg" alt="" width="234" height="256" />
        </div>
        {/* /.main__decoration main__decoration-/-flower-light-small */}

        <div className="main__decoration main__decoration--flower-light-smallest">
          <img src="/assets/images/temp/flower-light.svg" alt="" width="216" height="237" />
        </div>
        {/* /.main__decoration main__decoration-/-flower-light-smallest */}

        <div className="main__decoration main__decoration--leaf-gray-top">
          <img src="/assets/images/temp/leaf-grey@2x.png" alt="" width="123" height="111" />
        </div>
        {/* /.main__decoration main__decoration-/-leaf-gray-top */}

        <div className="main__decoration main__decoration--leaf-gray-bottom">
          <img src="/assets/images/temp/leaf-grey@2x.png" alt="" width="123" height="111" />
        </div>
        {/* /.main__decoration main__decoration-/-leaf-gray-bottom */}

        <div className="main__decoration main__decoration--leaf-gold">
          <img src="/assets/images/temp/leaf-gold@2x.png" alt="" width="78" height="134" />
        </div>
        {/* /.main__decoration main__decoration-/-leaf-gold */}

        <div className="main__decoration main__decoration--two-leaves">
          <img src="/assets/images/temp/two-leaves@2x.png" alt="" width="160" height="174" />
        </div>
        {/* /.main__decoration main__decoration-/-two-leaves */}
      </div>
      {/* /.main__decorations */}

      <div className="hero">
        <div className="shell shell--small">
          <div className="hero__inner">
            <div className="hero__logo">
              <img
                src={isEnglish ? "/assets/images/temp/logo.svg" : "/assets/images/svg/logo-fr.svg"}
                alt="Logo"
              />
            </div>

            <div className="hero__content">
              <p> {t("home.1")}</p>
            </div>
            {/* /.hero__content */}
          </div>
          {/* /.hero__inner */}
        </div>
        {/* /.shell */}
      </div>
      {/* /.hero */}

      <section className="section">
        <div className="shell shell--small">
          <div className="section__inner">
            <div className="section__cards">
              <div className="cards">
                <div className="cards__items">
                  <div className="cards__item">
                    <div className="card">
                      <div className="card__image">
                        <img src="/assets/images/temp/guide1.png" alt="" />
                      </div>
                      {/* /.card__image */}

                      <div className="card__content">
                        <h3>{t("home.2")}</h3>

                        <div className="card__entry">
                          <p>{t("home.3")}</p>
                        </div>
                        {/* /.card__entry */}

                        <div className="card__actions">
                          <Link
                            to="/guides"
                            className="btn btn--block"
                            onClick={() => eventClick("guideLink")}>
                            {t("home.4")}
                          </Link>

                          {/* <a href="/" className="btn btn--block" onClick={() => eventClick("game")}>
                            {t("home.9")}
                          </a> */}
                        </div>
                        {/* /.card__actions */}
                      </div>
                      {/* /.card__content */}
                    </div>
                    {/* /.card */}
                  </div>
                  {/* /.cards__item */}

                  <div className="cards__item">
                    <div className="card">
                      <div className="card__image">
                        <img src="/assets/images/temp/charity1.png" alt="" />
                      </div>
                      {/* /.card__image */}

                      <div className="card__content">
                        <h3>{t("home.5")}</h3>

                        <div className="card__entry">
                          <p>{t("home.6")}</p>
                        </div>
                        {/* /.card__entry */}

                        <div className="card__actions" onClick={() => eventClick("learn")}>
                          <Link to="/charities" className="btn btn--block">
                            {t("home.7")}
                          </Link>
                        </div>
                        {/* /.card__actions */}
                      </div>
                      {/* /.card__content */}
                    </div>
                    {/* /.card */}
                  </div>
                  {/* /.cards__item */}

                  <div className="cards__item">
                    <div className="card">
                      <div className="card__image">
                        <img src="/assets/images/temp/products1.png" alt="" />
                      </div>
                      {/* /.card__image */}

                      <div className="card__content">
                        <h3>{t("home.8")}</h3>

                        <div className="card__entry">
                          <p>{t("home.9")}</p>
                        </div>
                        {/* /.card__entry */}

                        <div className="card__actions">
                          <a
                            href={isEnglish ? GameLinks.en : GameLinks.fr}
                            rel="noopener noreferrer"
                            target="_blank"
                            className="btn btn--block"
                            onClick={() => eventClick("game")}>
                            {t("home.10")}
                            {/* {t("home.12")} */}
                          </a>
                        </div>
                        {/* /.card__actions */}
                      </div>
                      {/* /.card__content */}
                    </div>
                    {/* /.card */}
                  </div>
                  {/* /.cards__item */}
                </div>
                {/* /.cards__items */}
              </div>
              {/* /.cards */}
              <p className="small-text">{t("home.11")}</p>
            </div>
            {/* /.section__cards */}
          </div>
          {/* /.section__inner */}
        </div>
        {/* /.shell */}
      </section>
      {/* /.section */}
    </div>
  );
}
