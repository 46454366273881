import { defaultStorageHelper } from "@spry/campaign-client";
import { changeLanguage } from "i18next";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === "en";
  const languageToggle = () => {
    const ln = isEnglish ? "fr" : "en";
    defaultStorageHelper.set("lang", ln);
    changeLanguage(ln);
    document.documentElement.setAttribute("lang", ln);
  };

  //Redirect to Fr
  useEffect(() => {
    if (window.location.href.includes("/fr")) {
      window.location.replace("https://partagezcadbury.ca");
    }
  }, []);

  return (
    <footer className="footer">
      <div className="shell">
        <div className="footer__inner">
          <div className="footer__content">
            <nav className="footer__nav">
              <ul>
                <li>
                  <a
                    href={
                      isEnglish
                        ? "https://www.mdlzcusthelp.ca/en-CA"
                        : "https://www.mdlzcusthelp.ca/fr-CA/"
                    }
                    aria-label={
                      isEnglish
                        ? "New tab - Mondelez Mondelez Contact Us"
                        : "Nouvel onglet - Mondelez Contactez-nous"
                    }
                    rel="noopener noreferrer"
                    target="_blank">
                    {t("footer.1")}
                  </a>
                </li>

                <li>
                  <a
                    href={
                      isEnglish
                        ? "https://www.mondelezinternational.com/Privacy-Policy/"
                        : "https://www.mondelezinternational.com/canada-french/privacy-policy/"
                    }
                    aria-label={
                      isEnglish
                        ? "New tab - Mondelez Privacy Policy"
                        : "Nouvel onglet - Mondelez Politique de confidentialité"
                    }
                    rel="noopener noreferrer"
                    target="_blank">
                    {t("footer.2")}
                  </a>
                </li>

                <li>
                  <a
                    href={
                      isEnglish
                        ? "https://www.mondelezinternational.com/assets/Country/Canada/Accessibility_Plan_and_Policies_for_Mondelez_Canada.pdf"
                        : "https://www.mondelezinternational.com/assets/Country/Canada/French/Accessibility_Plan_and_Policies_for_Mondelez_Canada.pdf"
                    }
                    aria-label={
                      isEnglish
                        ? "New tab - Mondelez Accessibilities Policy"
                        : "Nouvel onglet - Mondelez Politique d'accessibilité"
                    }
                    rel="noopener noreferrer"
                    target="_blank">
                    {t("footer.3")}
                  </a>
                </li>

                <li>
                  <a
                    href={
                      isEnglish
                        ? "https://www.mondelezinternational.com/Terms-of-Use/"
                        : "https://www.mondelezinternational.com/canada-french/legal-notice/"
                    }
                    aria-label={
                      isEnglish
                        ? "New tab - Mondelez Terms of Use"
                        : "Nouvel onglet - Mondelez Modalités d'utilisation"
                    }
                    rel="noopener noreferrer"
                    target="_blank">
                    {t("footer.4")}
                  </a>
                </li>

                <li>
                  <a
                    href={
                      isEnglish
                        ? "https://snackworks.ca/en/cookies"
                        : "https://www.snackworks.ca/fr/cookies"
                    }
                    aria-label={
                      isEnglish
                        ? "New tab - Mondelez Cookie Policy"
                        : "Nouvel onglet - Mondelez Politique en matière de fichiers témoins (cookies)"
                    }
                    rel="noopener noreferrer"
                    target="_blank">
                    {t("footer.5")}
                  </a>
                </li>
                <li>
                  <a
                    href={
                      isEnglish
                        ? "https://www.mondelezinternational.com/canada/quebec-privacy-officer/"
                        : "https://www.mondelezinternational.com/canada-french/quebec-privacy-officer/"
                    }
                    aria-label={
                      isEnglish
                        ? "New tab - Mondelez Quebec Privacy"
                        : "Nouvel onglet - Mondelez Responsable de la protection de la vie privée, Québec"
                    }
                    rel="noopener noreferrer"
                    target="_blank">
                    {t("footer.6")}
                  </a>
                </li>

                <li>
                  <button className="btn_language-toggle" onClick={languageToggle}>
                    {t("footer.7")}
                  </button>
                </li>
              </ul>
            </nav>
            {/* /.footer__nav */}

            <p className="footer__copyright">{t("footer.8")}</p>
          </div>
          {/* /.footer__content */}

          <div className="footer__decoration">
            <picture>
              <source media="(max-width: 767px)" srcSet="/assets/images/temp/bunny-mobile.svg" />

              <img src="/assets/images/temp/bunny.svg" alt="Bunny decoration" />
            </picture>
          </div>
          {/* /.footer__decoration */}
        </div>
        {/* /.footer__inner */}
      </div>
      {/* /.shell */}
    </footer>
  );
}

export default Footer;
