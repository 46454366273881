import { Route } from "react-router-dom";
import "../i18n";
import Open from "../Pages/Open";
import Splash from "../Pages/Open/Splash";
import ComingSoon from "../Pages/ComingSoon";
import Closed from "../Pages/Closed";
import Charities from "../Pages/Open/Charities";
import Guides from "../Pages/Open/Guides";
import AgeGate from "../Pages/Open/AgeGate";

export default (
  <>
    <Route path="/" element={<Open />}>
      <Route path="/" element={<AgeGate />} />
      <Route path="/splash" element={<Splash />} />
      <Route path="/charities" element={<Charities />} />
      <Route path="/guides" element={<Guides />} />
      <Route path="/fr" element={<Open />} />
    </Route>

    <Route path="closed" element={<Closed />} />
    <Route path="coming" element={<ComingSoon />} />
  </>
);
