import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { eventClick } from "../../Pages/Open/Splash";
import { defaultStorageHelper } from "@spry/campaign-client";

function Header() {
  const { t, i18n } = useTranslation();
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isHeader, setIsHeader] = useState(false);

  const data = defaultStorageHelper.get("dob");
  useEffect(() => {
    data && setIsHeader(true);
  }, [data]);

  return (
    <header className={isMenuActive ? "header is-active" : "header"}>
      <div className="shell">
        {isHeader && (
          <div className="header__inner">
            <div className="header__menu" onClick={() => setIsMenuActive(false)}>
              <Link to="/" className="logo">
                <img
                  src={
                    i18n.language === "en"
                      ? "/assets/images/temp/logo.svg"
                      : "/assets/images/svg/logo-fr.svg"
                  }
                  alt=""
                />
              </Link>

              <nav className="nav">
                <ul>
                  <li>
                    <Link to="/guides">{t("nav.1")}</Link>
                  </li>

                  <li>
                    <Link to="/charities" onClick={() => eventClick("learn")}>
                      {t("nav.2")}
                    </Link>
                  </li>

                  <li>
                    <a
                      href={
                        i18n.language === "en"
                          ? "https://hideandfind.sharecadbury.ca/en/age"
                          : " https://hideandfind.sharecadbury.ca/fr/age"
                      }
                      rel="noopener noreferrer"
                      target="_blank"
                      onClick={() => eventClick("game")}>
                      {t("home.10")}
                    </a>
                  </li>
                </ul>
              </nav>
              {/* /.nav */}
            </div>
            {/* /.header__menu */}

            <span
              style={{ cursor: "pointer" }}
              onClick={() => setIsMenuActive(!isMenuActive)}
              className={isMenuActive ? "nav-trigger is-active" : "nav-trigger"}>
              <span></span>

              <span></span>

              <span></span>
            </span>
          </div>
        )}
        {/* /.header__inner */}
      </div>
      {/* /.shell */}
      <a href="#main" className="skip">
        Skip to main content
      </a>
    </header>
  );
}

export default Header;
