import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { eventClick } from "../Splash";
import { useEffect } from "react";
import { defaultStorageHelper } from "@spry/campaign-client";

export default function Guides() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const data = defaultStorageHelper.get("dob");
    !data && navigate("/");
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main">
      <div className="main__decorations">
        <div className="main__decoration main__decoration--flower-dark-big">
          <img src="/assets/images/temp/flower-dark.svg" alt="" width="514" height="560" />
        </div>
        {/* /.main__decoration main__decoration-/-flower-dark-big */}

        <div className="main__decoration main__decoration--flower-dark-medium">
          <img src="/assets/images/temp/flower-dark.svg" alt="" width="441" height="480" />
        </div>
        {/* /.main__decoration main__decoration-/-flower-dark-medium */}

        <div className="main__decoration main__decoration--flower-dark-small">
          <img src="/assets/images/temp/flower-dark.svg" alt="" width="180" height="197" />
        </div>
        {/* /.main__decoration main__decoration-/-flower-dark-small */}

        <div className="main__decoration main__decoration--flower-light-big">
          <img src="/assets/images/temp/flower-light.svg" alt="" width="409" height="447" />
        </div>
        {/* /.main__decoration main__decoration-/-flower-light-big */}

        <div className="main__decoration main__decoration--flower-light-small">
          <img src="/assets/images/temp/flower-light.svg" alt="" width="234" height="256" />
        </div>
        {/* /.main__decoration main__decoration-/-flower-light-small */}

        <div className="main__decoration main__decoration--flower-light-smallest">
          <img src="/assets/images/temp/flower-light.svg" alt="" width="216" height="237" />
        </div>
        {/* /.main__decoration main__decoration-/-flower-light-smallest */}

        <div className="main__decoration main__decoration--leaf-gray-top">
          <img src="/assets/images/temp/leaf-grey@2x.png" alt="" width="123" height="111" />
        </div>
        {/* /.main__decoration main__decoration-/-leaf-gray-top */}

        <div className="main__decoration main__decoration--leaf-gray-bottom">
          <img src="/assets/images/temp/leaf-grey@2x.png" alt="" width="123" height="111" />
        </div>
        {/* /.main__decoration main__decoration-/-leaf-gray-bottom */}

        <div className="main__decoration main__decoration--leaf-gold">
          <img src="/assets/images/temp/leaf-gold@2x.png" alt="" width="78" height="134" />
        </div>
        {/* /.main__decoration main__decoration-/-leaf-gold */}

        <div className="main__decoration main__decoration--two-leaves two-leaves">
          <img src="/assets/images/temp/two-leaves@2x.png" alt="" width="160" height="174" />
        </div>
        {/* /.main__decoration main__decoration-/-two-leaves */}
      </div>
      {/* /.main__decorations */}

      <div className="hero">
        <div className="shell shell--small">
          <div className="hero__inner">
            <Link to="/splash" className="hero__logo">
              <img
                src={
                  i18n.language === "en"
                    ? "/assets/images/temp/logo.svg"
                    : "/assets/images/svg/logo-fr.svg"
                }
                alt="Logo"
              />
            </Link>

            <div className="hero__content textLeft">
              <h1>{t("guide.1")}</h1>

              <p> {t("guide.2")} </p>
            </div>
            {/* /.hero__content */}
          </div>
          {/* /.hero__inner */}
        </div>
        {/* /.shell */}
      </div>
      {/* /.hero */}

      <section className="section ">
        <div className="shell shell--small">
          <div className="section__inner">
            <div className="cards__item flexCenter">
              <div className="card width50 guideWIdth">
                <div className="card guide">
                  <div className="guide-card text-center">
                    <h3 className="marginBottom0">{t("guide.4")}</h3>
                    <div className="">
                      <img
                        src={
                          i18n.language === "en"
                            ? "/assets/images/temp/cover_en.png"
                            : "/assets/images/temp/cover_fr.png"
                        }
                        alt=""
                      />
                    </div>
                    {/* /.card__image */}

                    <div className="card__actions ">
                      <a
                        href={
                          i18n.language === "en"
                            ? "/assets/guides/HideGuide_Booklet_EN.pdf"
                            : "/assets/guides/HideGuide_Booklet_FR.pdf"
                        }
                        download={"guide_" + new Date().toLocaleString()}
                        onClick={() => eventClick("download")}
                        className="btn btn--block"
                        rel="noopener noreferrer"
                        target="_blank">
                        {t("guide.3")}
                      </a>
                    </div>
                    {/* /.card__actions */}
                  </div>
                  {/* /.card__content */}
                </div>
                {/* /.card */}
              </div>
              {/* /.card */}
            </div>
            {/* /.cards__item */}
          </div>
          {/* /.section__inner */}
        </div>
        {/* /.shell */}
      </section>
      {/* /.section */}
    </div>
  );
}
